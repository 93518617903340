import { Component, OnInit, ViewChild, ElementRef, Inject, Renderer2, AfterViewInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from "../../../services/auth.service";
import Swal from 'sweetalert2';
import { HttpService } from "../../../services/http.service";
import { environment } from "../../../../environments/environment";
import { User } from "../../../shared/user";
import { RolesService } from "../../../services/roles.service";
import { CommonService } from "../../../services/common.service";
import { EventsService } from "../../../services/events.service";
import { Subscription } from "rxjs";
import { FirestoreService } from "../../../services/firestore.service";
import { NotificationSoundService } from "../../../services/notification-sound.service";
import { DataSharingService } from "../../../services/data-sharing.service";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  newOrders: any[] = [];
  currency: string = environment.currency;
  public user: User;

  collectionName = 'Users';
  collectionField = 'is_super';
  orderByDir: any = 'asc';
  users: Subscription;
  collectionName2 = 'Orders';

  collectionField2 = 'order_createday';
  orderByDir2: any = 'desc';
  orders: Subscription;
  private alert_new_order_sound: boolean = localStorage.getItem('alert_new_order_sound') == 'true' || false;
  admin_commerce: string = "";
  adminCommerceData: any = {};

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private router: Router,
    private auth: AuthService,
    private service: HttpService,
    private rolesService: RolesService,
    private commonService: CommonService,
    private events: EventsService,
    private firestore: FirestoreService,
    private notificacionSound: NotificationSoundService,
    private dataSharingService: DataSharingService,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) this.rolesService.verifyRoles().then(value => {
        let url = this.router.url;
        let title = url.substring(url.indexOf('/') + 1);
        let page = title.split('/');
        // console.log('value from navbar promise', value)
        // if (!value) this.commonService.alertPermissionDenied(page[0]);
      });
    });

    this.getOrdersByStatus();

    // this.getUser();

    this.users = this.firestore.search(this.collectionName, {
      'orderByName': this.collectionField,
      'orderByDir': this.orderByDir,
    }).subscribe(res1 => {
      this.getUser();
    })
  }

  ngOnInit(): void {
    // this.events.publish('alert:sound', {from: 'header', sound: true});
    document.addEventListener('click', () => {
      this.subscribeAlertSound();
    });
  }

  subscribeAlertSound() {
    // Suscribirse al Observable para recibir los valores actualizados
    this.dataSharingService.alert_new_order_sound.subscribe((valor: boolean | string) => {
      // Actualizar el valor de alert_new_order_sound
      this.alert_new_order_sound = valor == 'true';
      if (valor == 'true') {
        this.playNotification();
        // this.notificacionSound[this.newOrders.length > 0 ? 'playNotificationSound' : 'stopNotificationSound']();
      } else {
        this.notificacionSound.stopNotificationSound();
      }
    });
  }

  playNotification() {
    if (this.newOrders.length > 0) {
      this.notificacionSound.requestPermission().subscribe(permission => {
        // console.log('permiso que tiene ',permission)
        // if (permission === 'granted') {
        this.notificacionSound.playNotificationSound();
        // } else {
        //   console.log('Permiso denegado para reproducir sonidos.');
        // }
      });
    }
  }

  /**
   * Sidebar toggle on hamburger button click
   */
  toggleSidebar(e: Event) {
    e.preventDefault();
    this.document.body.classList.toggle('sidebar-open');
  }

  getOrdersByStatus() {
    this.orders = this.firestore.search(this.collectionName2, {
      'orderByName': this.collectionField2,
      'orderByDir': this.orderByDir2,
    }).subscribe(res1 => {
      this.service.getOrdersByStatus('New').subscribe(res => {
        console.log("res", res);
        if (res.listing.length > 0) {
          this.newOrders = res.listing;
          // this.alert_new_order_sound == true ? this.notificacionSound.playNotificationSound() : this.notificacionSound.stopNotificationSound();
          this.alert_new_order_sound == true ? this.playNotification() : this.notificacionSound.stopNotificationSound();
        } else {
          this.notificacionSound.stopNotificationSound();
        }
      })
    })
  }

  getUser() {
    this.auth.getUser2().then(async (userLocal: any) => {
      this.user = userLocal;
      localStorage.setItem('is_super', String(this.user.is_super));
      if (this.user.is_admin_commerce) {
        this.admin_commerce = userLocal.admin_commerce[0];

        this.firestore.getOneWatcher('BusinessPlace', this.admin_commerce).subscribe(res => {
          console.log("BusinessPlace | managers: ", res);
          this.adminCommerceData = res;
        });
      }
    }, (e) => {

    });
  }

  /**
   * Logout
   * @param e - evento del click en el botón de cerrar sesión
   */
  onLogout(e: Event): void {
    e.preventDefault(); // Evita la acción por defecto del evento (en este caso, seguir un enlace)

    Swal.fire({ // Muestra un mensaje mientras se procesa el cierre de sesión
      icon: 'info',
      text: 'Logging out, please wait a moment',
      showConfirmButton: false,
      backdrop: 'static',
      allowOutsideClick: false,
    });

    this.auth.logout() // Llama a la función de cierre de sesión
      .then(() => {
        localStorage.clear(); // Borra el almacenamiento local
        this.router.navigate(['/auth/login']) // Redirige a la página de inicio de sesión
          .then(() => Swal.close()); // Cierra el mensaje de espera
      })
      .catch((error) => console.log(error)); // Maneja cualquier error que pueda ocurrir
  }



}
