export const environment = {
    production: false,
    stripe_token: 'STRIPE_TOKEN',
    paypal_token: 'PAYPAL_TOKEN',
    loadGif: 'assets/images/loader.gif',
    currency: '',

    // // PARAMETROS NEW ROLLING PRO
    firebase: {
        apiKey: "AIzaSyBgmFSjwQsnXaWREGx6wKIJ23c5_qhCeqI",
        authDomain: "newrolling-pro.firebaseapp.com",
        projectId: "newrolling-pro",
        storageBucket: "newrolling-pro.appspot.com",
        messagingSenderId: "883404852630",
        appId: "1:883404852630:web:c9496b6151d0689e0861b7",
        measurementId: "G-8E9QPRPXFE"
    },

    urlBase: 'https://us-central1-newrolling-pro.cloudfunctions.net',
    urlBaseWompy: 'https://us-central1-newrolling-pro.cloudfunctions.net/Wompi',
    urlBaseNR: 'https://api.newrolling.com',
    apikey: '97ccc90e8fe-cce525a10ddf3-4cf7-415a29cd',
    googleMapsKey: 'AIzaSyCDk6GYGJWDeVPbgqlfOKGSXZ-9-eXBpW4',

    // CREDENCIALES DE PRO New Rolling
    wompi: {
        grant_type: 'client_credentials', // Dejar siempre client_credentials.
        audience: 'wompi_api', // Dejar siempre "wompi_api"
        client_id: 'aa4c30e1-0757-4d03-a20f-b9cc2663bf5c', // El client id del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
        client_secret: '259c97c1-06fe-4137-ab29-b331b92f0fcb', // Es la llave secreta del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
    },

    // TWILIO CREDENCIALES NEW ROLLING PRO
    twilio: {
        TWILIO_SERVICE_ENDPOINT: 'https://verify.twilio.com/v2/Services/VA037cd4a1d0556aceea8d4d9524af6c24/Verifications',
        TWILIO_SERVICE_VERIFICATION_CHECK: `https://verify.twilio.com/v2/Services/VA037cd4a1d0556aceea8d4d9524af6c24/VerificationCheck`,
        TWILIO_AUTH_TOKEN: 'eae5d7503587447a21af4311385d376c',
        TWILIO_ACCOUNT_SID: 'ACe4c513b1b576a81a7304c06951362f8c',
        TWILIO_SERVICE_TOKEN: 'VA037cd4a1d0556aceea8d4d9524af6c24',
        TWILIO_MESSAGING_SERVICE_SID: 'MGdb0d97a058ccfdc75431f7c1aa3a553d'
    },

    mailchimp: {
        apiKey: '28100cb5222079f4e991ec8a5dd7789a-us17',
        server: 'us17',
        listUniqueId: 'a10147ff75'
    }
};

